import "../App.css";
import { Header } from "../Components/Header";
import { MainHero } from "../Components/MainHero";
import { MainHeroImage } from "../Components/MainHeroImage";
import { Canvas } from "../Components/Canvas";
import { ProductGrid } from "../Components/ProductGrid";
import { Features } from "../Components/Features";
import { Pricing } from "../Components/Pricing";
import { Castillos } from "../Components/Castillos";
import { Footer } from "../Components/Footer";


function App() {
  return (
    <div className={`bg-background grid overflow-hidden`}>
      <div className={`relative bg-background`}>
        <div className="max-w-7xl mx-auto">
          <div
            className={`relative z-10 pb-8 bg-background sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32`}>
            <Header />
            <MainHero />
          </div>
        </div>
        <MainHeroImage />
      </div>
      <Canvas />
      <Castillos />
      <ProductGrid />
      <Features />
      <Pricing />
      <Footer />
    </div>
  );
}

export default App;
