export const ProductGridTitle = ({ children }) => {
  return (
    <>
      <h2 className="text-center mt-10 mb-5 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-3xl lg:text-4xl xl:text-6xl">
        {children.split(" ")[0]}{" "}
        <span className="text-blue-600 dark:text-blue-500">
          {children.split(" ")[1]}
        </span>{" "}
      </h2>
    </>
  );
};
