import { useState } from "react";
import { ProductModal } from "./ProductModal";
export const Product = ({ Producto }) => {
  const { name, src, alt, description, pricing, size } = Producto;
  const [openModal, setOpenModal] = useState();
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const textoCortado =
    description.length > 15
      ? `${description.substring(0, 60)}...`
      : description;
  return (
    <>
      <div
        dataModalTarget="default-modal"
        dataModalToggle="default-modal"
        className="max-w-xs rounded overflow-hidden shadow-lg cursor-pointer"
        title="Ver detalles"
        onClick={handleOpenModal}>
        <img
          src={src}
          alt={alt}
          className="w-full cursor-pointer h-44 object-cover"
        />
        <div className="px-6 py-4 h-32">
          <div className="font-bold text-base text-gray-700 mb-2 cursor-pointer hover:text-main-900 lg:text-lg md:text-md xl:text-xl">
            {name.toUpperCase()}
          </div>
          <p className="text-gray-700 hidden text-base sm:block">
            {textoCortado}
          </p>
        </div>
        <div className="px-6 py-4">
          <span className="text-gray-600 font-bold text-xl cursor-pointer">
            {pricing}
          </span>
        </div>
      </div>
      <ProductModal
        imgSrc={src}
        description={description}
        price={pricing}
        name={name}
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        size={size}
      />
    </>
  );
};
