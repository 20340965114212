import Wave from "react-wavify";

export const Canvas = () => {
  return (
    <Wave
      fill="#d2f3fb"
      paused={false}
      style={{ display: "flex" }}
      options={{
        height: 10,
        amplitude: 35,
        speed: 0.15,
        points: 3,
      }}
    />
    // <div className="flex align-top">
    //   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
    //     <path
    //       fill="#d2f3fb"
    //       fill-opacity="1"
    //       d="M0,288L26.7,256C53.3,224,107,160,160,149.3C213.3,139,267,181,320,176C373.3,171,427,117,480,106.7C533.3,96,587,128,640,165.3C693.3,203,747,245,800,250.7C853.3,256,907,224,960,197.3C1013.3,171,1067,149,1120,128C1173.3,107,1227,85,1280,96C1333.3,107,1387,149,1413,170.7L1440,192L1440,0L1413.3,0C1386.7,0,1333,0,1280,0C1226.7,0,1173,0,1120,0C1066.7,0,1013,0,960,0C906.7,0,853,0,800,0C746.7,0,693,0,640,0C586.7,0,533,0,480,0C426.7,0,373,0,320,0C266.7,0,213,0,160,0C106.7,0,53,0,27,0L0,0Z"></path>
    //   </svg>
    // </div>
  );
};
