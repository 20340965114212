export const MainHero = () => {
  return (
    <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
      <div className="sm:text-center lg:text-left">
        <h1 className="text-4xl tracking-tight font-extrabold text-main-950 sm:text-5xl md:text-6xl animate-fade-down animate-once animate-duration-1000">
          <span className="block xl:inline">
            Hinchables <span className="text-main-500">Acosta</span>
          </span>{" "}
        </h1>
        <span
          className={`block tracking-tight text-gray-700 text-2xl font-bold xl:inline animate-fade-left animate-duration-[2000ms] animate-delay-500 animate-ease-in-out`}>
          Hinchables y articulos de fiesta
        </span>
        <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0 animate-fade-right animate-duration-[2500ms] animate-delay-500 animate-ease-in-out">
          Trabajamos para toda la provincia de Cádiz, echa un ojo a nuestros
          productos y contáctanos para mas información
        </p>
        <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
          <div className="rounded-md shadow">
            <a
              href="#products"
              className={`w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-main-700 hover:bg-main-900 hover:text-primary  md:py-4 md:text-lg md:px-10 animate-jump animate-duration-600 animate-delay-[1500ms] animate-ease-in-out`}>
              Productos
            </a>
          </div>
          <div className="mt-3 sm:mt-0 sm:ml-3">
            <a
              href="#contact"
              className={`w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md border-primary text-main-700 bg-main-200 hover:bg-main-300 hover:text-primary md:py-4 md:text-lg md:px-10 animate-jump animate-duration-600 animate-delay-[1800ms] animate-ease-linear`}>
              Contacto
            </a>
          </div>
        </div>
      </div>
    </main>
  );
};
