import { SecurityIcon } from "./icons/SecurityIcon";
import { QualityIcon } from "./icons/QualityIcon";
import { HappyIcon } from "./icons/HappyIcon";
import { Element } from "react-scroll";
export const Features = () => {
  return (
    <Element name="about">
      <div
        className="py-12 bg-background my-10 px-4 sm:px-6 lg:px-8"
        id="features">
        <div className="max-w-7xl mx-auto">
          <div className="text-center lg:text-left">
            <h2 className="text-base text-primary font-semibold tracking-wide uppercase">
              Sobre nosotros
            </h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              La seguridad lo primero
            </p>
            <p className="mt-6 text-xl text-gray-500">
              En Hinchables Acosta, sabemos que la diversión va de la mano con
              la seguridad. Nos enorgullecemos de ofrecer una amplia gama de
              hinchables y juegos inflables que no solo garantizan momentos
              llenos de alegría y entretenimiento, sino que también priorizan la
              seguridad de quienes disfrutan de ellos.
            </p>
            <div className="mt-6 flex space-y-4 content-center sm:flex-row justify-center sm:justify-start items-center sm:space-x-4">
              <SecurityIcon />
              <QualityIcon />
              <HappyIcon />
            </div>
            <p className="mt-6 text-xl text-gray-500">
              Confía en nosotros para llevar la alegría a tus eventos y
              celebraciones, sabiendo que tu seguridad es nuestra prioridad
              número uno. ¡Únete a la diversión con total tranquilidad en
              Hinchables Acosta!
            </p>
          </div>
        </div>
      </div>
    </Element>
  );
};
