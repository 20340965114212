import { useEffect, useState } from "react";

export const PhoneIcon = () => {
  const [isPhone, setIsPhone] = useState(false);
  const [showMobile, setShowMobile] = useState(false);

  useEffect(() => {
    if (esDispositivoMovil()) {
      setIsPhone(true);
    } else {
      setIsPhone(false);
    }
  }, []);

  const esDispositivoMovil = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  };
  const handleClickPhone = () => {
    window.open("tel:+34652563689");
  };
  const handleCLickPC = () => {
    setShowMobile(true);
  };
  return (
    <>
      <button
        onClick={isPhone ? handleClickPhone : handleCLickPC}
        title="Telefono movil"
        type="button"
        data-te-ripple-init
        data-te-ripple-color="light"
        className="mb-2 inline-block rounded px-6 py-2.5 text-xs font-medium uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
        style={{ backgroundColor: "#159ac5" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-20 h-6">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
          />
        </svg>
      </button>
      {showMobile ? (
        <span className="tracking-wide text-main-900">+34 652563689</span>
      ) : (
        ""
      )}
    </>
  );
};
