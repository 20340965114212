import { useEffect } from "react";
import { useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const NotificationSection = ({
  Texto,
  Duration,
  ProgressBar,
  Position,
  theme,
  children,
}) => {
  const sectionRef = useRef(null);

  const options = {
    threshold: 0.9,
    root: document.body,
    rootMargin: "0px",
  };

  useEffect(() => {
    const { threshold, root, rootMargin } = options;

    const observer = new IntersectionObserver(
      (entries, observerInstance) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            toast.info(Texto, {
              position: Position,
              autoClose: Duration,
              hideProgressBar: !ProgressBar,
              pauseOnHover: false,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
              theme: theme,
              icon: "👆",
            });

            observerInstance.unobserve(entry.target);
          }
        });
      },
      { threshold, root, rootMargin }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, [options.threshold, options.root, options.rootMargin]);

  return (
    <div ref={sectionRef}>
      {children}
      <ToastContainer
        position="top-center"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};
