import { WhatsappIcon } from "./icons/WhatsappIcon";
import { PhoneIcon } from "./icons/PhoneIcon";
import { GoogleIcon } from "./icons/GoogleIcon";
import { Element } from "react-scroll";

export const Pricing = () => {
  return (
    <Element name="contact">
      <section className={`bg-background`} id="contact">
        <div className={`container mx-auto pt-4 text-primary`}>
          <h1
            className={`w-full text-5xl font-bold leading-tight text-center mt-10 text-main-950`}>
            Contacto
          </h1>
          <div className={`w-full`}>
            <div
              className={`h-1 mx-auto bg-primary w-64 opacity-25 my-0 py-0 rounded-t`}></div>
          </div>
          <div
            className={`flex flex-col sm:flex-row justify-center py-12 sm:my-4`}>
            <div
              className={`flex flex-col w-5/6 lg:w-1/3 mx-auto lg:mx-0 rounded-lg bg-background sm:-mt-6 shadow-lg z-10`}>
              <div
                className={`flex-1 bg-background rounded-t rounded-b-none overflow-hidden shadow`}>
                <div
                  className={`w-full p-8 text-3xl font-bold text-center text-main-700`}>
                  Pulsa para contactarnos
                </div>
                <div
                  className={`h-1 w-full bg-primary my-0 py-0 rounded-t`}></div>
                <ul className={`w-full text-center text-xl font-bold`}>
                  <li
                    className={`border-b py-4 flex flex-wrap items-center flex-col gap-3`}>
                    <span className="text-main-900 ">Whatsapp</span>
                    <WhatsappIcon />
                  </li>
                  <li
                    className={`border-b py-4 flex flex-wrap items-center flex-col gap-3`}>
                    <span className="text-main-900 ">Móvil</span>
                    <PhoneIcon />
                  </li>
                  <li
                    className={`border-b py-4 flex flex-wrap items-center flex-col gap-3`}>
                    <span className="text-main-900 ">Google Maps</span>
                    <GoogleIcon />
                  </li>
                </ul>
              </div>
              <div
                className={`flex-none mt-auto bg-background rounded-b rounded-t-none overflow-hidden shadow p-6`}></div>
            </div>
          </div>
        </div>
      </section>
    </Element>
  );
};
