import PorteriasFutbol from "../images/PorteriasFutbol.jpeg";
import Toro from "../images/Toro.jpeg";
import PaloLoco from "../images/PaloLoco.jpeg";
import Porteria from "../images/Porteria.jpeg";
import FutbolinHumano from "../images/FutbolinHumano.jpeg";
import AlgodonAzucar from "../images/AlgodonAzucar.jpeg";
import Palomitas from "../images/Palomitas.jpeg";
import Deslizador from "../images/Deslizador.jpg";
import DeslizadorCalle from "../images/DeslizadorCalle.jpeg";
import DeslizadorCalle2 from "../images/Deslizador2.jpg";
import CarrosGolosinas from "../images/CarrosGolosinas.jpeg";

export const products = [
  {
    name: "Toro Mecánico",
    src: Toro,
    alt: "Toro mecanico para fiestas",
    description: "Toro mecánico con intensidad regulable. Apto para todas las edades, todo tipo de fiestas y celebraciones",
    pricing: "240€",
    size:"5m diametro"
  },
  {
    name: "El Palo loco",
    src: PaloLoco,
    alt: "Palo giratorio para fiestas",
    description: "Palo giratorio con colchoneta hinchable, juego ideal para despedidas, fiestas y celebraciones",
    pricing: "230",
    size:"7x7 metros"
  },
  {
    name: "Futbolin humano",
    src: PorteriasFutbol,
    alt: "Porterías de futbol hinchables",
    description: "Mini campo de futbol hinchable para pachangas o penaltis",
    pricing: "70€",
    size:"9x6 metros"
  },
  {
    name: "Tiro a puerta",
    src: Porteria,
    alt: "Porteria de penaltis con puntos",
    description: "Porteria de penaltis para jovenes y niños, con sistema de puntuaje",
    pricing: "50€",
    size:" "
  },
  {
    name: "Deslizador Acuático 2 Calles",
    src: Deslizador,
    alt: "Deslizador acuático piscina",
    description: "Deslizador acuatico para fiestas de verano (Se le puede añadir una piscina hinchable al final de la misma, o quitarla según sea necesario)",
    pricing: "80€",
    size:"9x3 metros"
  },
  {
    name: "Deslizador Acuático 1 Calle",
    src: DeslizadorCalle,
    alt: "Deslizador acuático piscina",
    description: "Deslizador acuatico para fiestas de verano con una sola calle mas larga(Se le puede añadir una piscina hinchable al final de la misma, o quitarla según sea necesario)",
    pricing: "70€",
    size:"9x2.5 metros"
  },
  {
    name: "Deslizador Acuático 1 Calle II",
    src: DeslizadorCalle2,
    alt: "Deslizador acuático piscina",
    description: "Deslizador acuatico para fiestas de verano con una sola calle mas larga(Se le puede añadir una piscina hinchable al final de la misma, o quitarla según sea necesario)",
    pricing: "70€",
    size:"9x2.5 metros"
  },
  {
    name: "Futbolín Humano grande",
    src: FutbolinHumano,
    alt: "Futbolín Humano para todo tipo de edades",
    description: "Futbolín humano con arnseses para todo tipo de edades, jovenes o pequeños",
    pricing: "80€",
  },
  {
    name: "Maquina Algodón de Azucar",
    src: AlgodonAzucar,
    alt: "Maquina de algodon de azucar sencilla",
    description: "Máquina de algodón de azucar para fiestas y cumpleaños, sencilla de usar y muy efectiva",
    pricing: "100€ /2h",
  },
  {
    name: "Maquina de palomitas",
    src: Palomitas,
    alt: "Maquina de palomitas sencilla",
    description: "Máquina de Palomitas para fiestas y cumpleaños, facil de servir y preparar",
    pricing: "100€ /2h",
  },
  {
    name: "Carros para golosinas",
    src: CarrosGolosinas,
    alt: "Carros para golosinas y snacks",
    description: "Carros diversos para golosinas y Snacks, a modo de presentación para fiestas y cumpleaños",
    pricing: "60€",
  },
];
