import { Product } from "./Product";
import { products } from "../data/product";
import { NotificationSection } from "./NotificationSection";
import { ProductGridTitle } from "./ProductsGridTitle";

export const ProductGrid = () => {
  return (
    /**Para ver las positions: https://fkhadra.github.io/react-toastify/introduction/ */
    <NotificationSection
      Texto={"Ve a productos y pincha en uno de ellos"}
      Duration={4000}
      ProgressBar={true}
      Position={"top-center"}
      theme={"light"}>
      <ProductGridTitle>Otros Productos</ProductGridTitle>

      <div
        id="products"
        className="p-8 grid grid-cols-2 gap-5 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {products.map((producto) => (
          <Product Producto={producto} />
        ))}
      </div>
    </NotificationSection>
  );
};
