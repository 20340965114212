import { Link } from "react-scroll";
export const ProductModal = ({
  name,
  imgSrc,
  description,
  price,
  size,
  handleCloseModal,
  openModal,
}) => {
  return (
    <>
      {/* <!-- Main modal --> */}
      {openModal && (
        // <!-- Main modal -->
        <div
          onClick={handleCloseModal}
          id="default-modal"
          tabIndex="-1"
          aria-hidden="true"
          className="fixed z-50 top-0 left-0 flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50">
          <div className="bg-white rounded-lg shadow-lg w-full md:max-w-2xl">
            {/* Modal header */}
            <div className="flex items-center justify-between p-4 md:p-5 border-b">
              <h3 className="text-xl font-semibold text-gray-900">{name}</h3>
              <button
                type="button"
                onClick={handleCloseModal}
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto flex justify-center items-center"
                data-modal-hide="default-modal">
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14">
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            {/* Modal body */}
            <div className="p-4 md:p-5 flex items-center wrap flex-col">
              <img
                src={imgSrc}
                className="h-72 rounded-lg shadow-[0px_10px_1px_rgba(221,_221,_221,_1),_0_10px_20px_rgba(204,_204,_204,_1)]"
                alt={`Imagen de ${name}`}
              />
              <div className="p-4">
                <p className="text-base leading-relaxed text-gray-500">
                  {description} <span className="font-bold">{size}</span>
                </p>
              </div>
              <p className="leading-relaxed text-gray-500">
                Cumpleaños: <span className="font-bold text-3xl">{price}</span>
              </p>
              <p className="text-red-400 text-xs text-center">
                Consultar precio para Comuniones, bodas, etc... (En caso de ser
                fuera de El Puerto habrá que abonar el transporte)
              </p>
            </div>
            {/* Modal footer */}
            <div className="flex items-center justify-center gap-16 p-4 md:p-5 border-t">
              <Link
                spy={true}
                delay={50}
                active="active"
                smooth={true}
                duration={200}
                to={"contact"}>
                <button
                  onClick={handleCloseModal}
                  data-modal-hide="default-modal"
                  type="button"
                  title="Contacto por teléfono o Whatsapp"
                  className="text-white bg-main-700 hover:bg-main-900 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3">
                  Contactar
                </button>
              </Link>
              <button
                onClick={handleCloseModal}
                data-modal-hide="default-modal"
                title="Volver a los productos"
                type="button"
                className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10">
                Atrás
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
