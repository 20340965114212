import { Element } from "react-scroll";
import { Product } from "./Product";
import { CastillosProducts } from "../data/CastillosProducts";
import { ProductGridTitle } from "./ProductsGridTitle";
export const Castillos = () => {
  return (
    <>
      <ProductGridTitle>Castillos Hinchables</ProductGridTitle>
      <Element name="products">
        <div
          id="products"
          className="p-8 grid grid-cols-2 gap-5 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {CastillosProducts.map((producto) => (
            <Product Producto={producto} />
          ))}
        </div>
      </Element>
    </>
  );
};
