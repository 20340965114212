import CastilloDisney from "../images/CastilloDisney.jpeg";
import CastilloPawPatrol from "../images/CastilloPawPatrol.jpeg";
import CastilloDibujos from "../images/CastilloDibujos.jpeg";
import CastilloMickey from "../images/CastilloMickey.jpeg";
import CastilloColores from "../images/CastilloColores.jpeg";
import CastilloColores2 from "../images/CastilloColores2.jpeg";
import CastilloColores3 from "../images/CastilloColores3.jpeg";
import CastilloAcuatico from "../images/CastilloAcuatico.jpeg";
import ToboganAcuatico from "../images/ToboganAcuatico.jpeg";
import CastilloColoresXL from "../images/CastilloColoresXL.jpeg";
import Castillo6x5 from "../images/Castillo 6x5.jpeg";
import UnicornioCastillo from "../images/CastilloUnicornio.jpg";
import MarioBrosCastillo from "../images/ArcoMarioBros.jpg"; 

export const CastillosProducts = [
  {
    name: "Castillo Arco Frozen",
    src: CastilloDisney,
    alt: "Castillo hinchable para niños tematica disney",
    description:
      "Castillo hinchable con tematica disney para niños,ideal para cumpleaños",
    pricing: "70€",
    size: "7x6 metros",
  },
  {
    name: "Castillo Hinchable Patrulla canina",
    src: CastilloPawPatrol,
    alt: "Castillo hinchable para niños tematica patrulla canina",
    description:
      "Castillo hinchable con tematica de la patrulla canina, ideal para cumpleaños",
    pricing: "60€",
    size: "6x4 metros",
  },
  {
    name: "Castillo Hinchable arco Bob esponja",
    src: CastilloDibujos,
    alt: "Castillo hinchable para niños tematica de varios personajes de dibujos aniamdos",
    description:
      "Castillo hinchable con tematica de varias series de dibujos animados, ideal para cumpleaños",
    pricing: "70€",
    size: "7x6 metros",
  },
  {
    name: "Castillo Hinchable Mickey Mouse",
    src: CastilloMickey,
    alt: "Castillo hinchable para niños tematica de Mickey y Minnie Mouse",
    description:
      "Castillo hinchable para niños tematica de Mickey y Minnie Mouse, ideal para cumpleaños y fiestas",
    pricing: "60€",
    size: "7x5 metros",
  },
  {
    name: "Castillo Hinchable Arco colores",
    src: CastilloColores,
    alt: "Castillo hinchable para niños colorido",
    description:
      "Castillo hinchable para niños colorido  ideal para cumpleaños, fiestas y comuniones",
    pricing: "70€",
    size: "7x5 metros",
  },
  {
    name: "Castillo Hinchable Plano",
    src: CastilloColores2,
    alt: "Castillo hinchable para niños colorido",
    description:
      "Castillo hinchable para niños colorido ideal para cumpleaños y todo tipo de fiestas",
    pricing: "50€",
    size: "5x4 metros",
  },
  {
    name: "Castillo Aquatico",
    src: CastilloAcuatico,
    alt: "Castillo hinchable acuatico",
    description:
      "Castillo hinchable con pequeña piscina en la parte final y tobogan hacia la misma, es acuatico e ideal para fiestas en verano o épocas de calor",
    pricing: "100€",
    size: "8x5.5 metros",
  },
  {
    name: "Tobogan Acuatico",
    src: ToboganAcuatico,
    alt: "Castillo hinchable acuatico",
    description:
      "Tobogan hinchable con pequeña piscina en la parte final ideal para fiestas en verano o épocas de calor",
    pricing: "100€",
    size: "10x4 metros",
  },
  {
    name: "Castillo Hinchable Plano",
    src: CastilloColores3,
    alt: "Castillo hinchable para niños colorido",
    description:
      "Castillo hinchable para niños colorido, ideal para cumpleaños y todo tipo de fiestas",
    pricing: "50€",
    size: "4x4 metros",
  },
  {
    name: "Castillo Hinchable Arco colores grande",
    src: CastilloColoresXL,
    alt: "Castillo hinchable para niños colorido",
    description:
      "Castillo hinchable para niños colorido, ideal para cumpleaños y todo tipo de fiestas",
    pricing: "80€",
    size: "7x7 metros",
  },
  {
    name: "Castillo Hinchable 6X5",
    src: Castillo6x5,
    alt: "Castillo hinchable para niños colorido",
    description:
      "Castillo hinchable para niños colorido, ideal para cumpleaños y todo tipo de fiestas",
    pricing: "60€",
    size: "7x7 metros",
  },
  {
    name: "Castillo Unicornio",
    src: UnicornioCastillo,
    alt: "Castillo hinchable con temática unicornio",
    description:
      "Castillo hinchable temática unicornio, colores llamativos, tamaño mediano",
    pricing: "50€",
    size: "3.2x4.5 metros",
  },
  {
    name: "Castillo Arco Mario Bros",
    src: MarioBrosCastillo,
    alt: "Castillo hinchable con temática super mario bros, con arco colorido",
    description:
      "Castillo hinchable temática mario bros, colores llamativos, tamaño mediano",
    pricing: "60€",
    size: "6x4 metros",
  },
];
